import { theme } from "@product/scmp-sdk";
import type { FragmentRefs } from "relay-runtime";

import { section as sectionData } from "shared/data/section";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";

import type { SubSectionWidget } from "./types";

export const getIncludeSectionFeatureMap = (entityId: string) => {
  const widgets = getSubSectionWidgets(entityId);
  return {
    includeChinaFutureTechDiscovery: widgets.includes("china-future-tech-discovery"),
    includeComment: widgets.includes("comment"),
    includeFocusArticle: widgets.includes("focus-article"),
    includeMultimedia: widgets.includes("multimedia"),
    includePostMagazine: widgets.includes("post-magazine"),
  };
};

export const getSubSectionWidgets = (entityId: string): SubSectionWidget[] => {
  switch (entityId) {
    case sectionData.announcements.entityId:
    case sectionData.recap.entityId:
      return [];
    case sectionData.asia.eastAsia.entityId:
    case sectionData.asia.southAsia.entityId:
    case sectionData.comment.asiaOpinion.entityId:
    case sectionData.comment.chinaOpinion.entityId:
    case sectionData.comment.worldOpinion.entityId:
    case sectionData.news.china.diplomacy.entityId:
    case sectionData.news.china.politics.entityId:
    case sectionData.news.hongKong.politics.entityId:
    case sectionData.thisWeekInAsia.economics.entityId:
    case sectionData.thisWeekInAsia.opinion.entityId:
    case sectionData.world.africa.entityId:
    case sectionData.world.americas.entityId:
    case sectionData.world.europe.entityId:
    case sectionData.world.middleEast.entityId:
    case sectionData.world.russiaCentralAsia.entityId:
    case sectionData.world.unitedStatesCanada.entityId:
      return ["open-questions-series-discovery", "comment", "trending-topics", "multimedia"];
    case sectionData.business.bankingAndFinance.entityId:
    case sectionData.business.chinaEVs.entityId:
    case sectionData.business.climateAndEnergy.entityId:
    case sectionData.business.companies.entityId:
    case sectionData.business.cryptocurrency.entityId:
    case sectionData.business.markets.entityId:
    case sectionData.economy.chinaEconomy.entityId:
    case sectionData.economy.economicIndicators.entityId:
    case sectionData.economy.globalEconomy.entityId:
    case sectionData.economy.policy.entityId:
    case sectionData.news.hongKong.hongKongEconomy.entityId:
    case sectionData.tech.bigTech.entityId:
    case sectionData.tech.policy.entityId:
    case sectionData.tech.techTrends.entityId:
    case sectionData.tech.techWar.entityId:
      return ["china-future-tech-discovery", "comment", "trending-topics", "multimedia"];
    case sectionData.chinaFutureTech.aerospace.entityId:
    case sectionData.chinaFutureTech.ai.entityId:
    case sectionData.chinaFutureTech.biomedicine.entityId:
    case sectionData.chinaFutureTech.evs.entityId:
    case sectionData.chinaFutureTech.robotics.entityId:
    case sectionData.chinaFutureTech.semiconductors.entityId:
    case sectionData.us.usElections.analysis.entityId:
    case sectionData.us.usElections.explainers.entityId:
    case sectionData.us.usElections.latest.entityId:
      return ["focus-article", "comment", "trending-topics", "multimedia"];
    case sectionData.lifestyle.artsAndCulture.entityId:
    case sectionData.lifestyle.familyAndRelationships.entityId:
    case sectionData.lifestyle.healthAndWellness.entityId:
    case sectionData.lifestyle.travelAndLeisure.entityId:
    case sectionData.news.peopleAndCulture.chinaPersonalities.entityId:
    case sectionData.news.peopleAndCulture.environment.entityId:
    case sectionData.news.peopleAndCulture.genderAndDiversity.entityId:
    case sectionData.news.peopleAndCulture.socialWelfare.entityId:
    case sectionData.news.peopleAndCulture.trendingInChina.entityId:
      return ["post-magazine", "comment", "multimedia", "trending-topics"];
    case sectionData.lifestyle.foodAndDrink.entityId:
    case sectionData.lifestyle.hundredTopTables.entityId:
    case sectionData.news.hongKong.healthAndEnvironment.entityId:
      return ["100-top-tables", "comment", "trending-topics", "multimedia"];
    default:
      return ["comment", "trending-topics", "multimedia"];
  }
};

export const getSubSectionAdsConfigs = (sectionId: string, adZone = "default") => {
  const defaultAdSlotProps: Partial<AdSlotProps> = {
    targeting: {
      paid: TargetingPaidType.NotArticle,
      scsid: [sectionId],
    },
    withBackground: true,
    withLabel: true,
    withSticky: true,
    zone: adZone,
  };

  const configs: Array<[AdSlotProps, AdSlotProps, AdSlotProps]> = [
    // AD2 = FirstAds in grid layout
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec1",
        breakpoint: theme.breakpoints.up("desktop"),
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec1",
        breakpoint: theme.breakpoints.only("tablet"),
        sizes: [[300, 250]],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_banner1",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250]],
      },
    ],
    // AD3
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec3",
        breakpoint: theme.breakpoints.up("desktop"),
        sizes: [[300, 250]],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec3",
        breakpoint: theme.breakpoints.only("tablet"),
        sizes: [
          [300, 250],
          [320, 480],
        ],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec1",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250]],
      },
    ],
    // AD4
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec3a",
        breakpoint: theme.breakpoints.up("desktop"),
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec3a",
        breakpoint: theme.breakpoints.only("tablet"),
        sizes: [[300, 250]],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec3",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [
          [300, 250],
          [320, 480],
        ],
      },
    ],
    // AD5
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec2",
        breakpoint: theme.breakpoints.up("desktop"),
        sizes: [[300, 250]],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec2",
        breakpoint: theme.breakpoints.only("tablet"),
        sizes: [[300, 250]],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec2",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [
          [300, 250],
          [320, 480],
        ],
      },
    ],
  ];

  return configs;
};

const chunkArray = (
  topicsStories: readonly {
    readonly " $fragmentSpreads": FragmentRefs<
      "oneSectionTopWidgetQueue" | "topStoriesBlockQueueItemsEdge"
    >;
  }[],
  hasNextPage: boolean,
) => {
  const chunks = [];
  const chunkSize = 4;

  for (let index = 0; index < topicsStories.length; index += chunkSize) {
    const chunk = topicsStories.slice(index, index + chunkSize);
    if (chunk.length === chunkSize || !hasNextPage) {
      chunks.push(chunk);
    }
  }

  return chunks;
};

export const getInitialTopStories = (
  topStories: readonly {
    readonly " $fragmentSpreads": FragmentRefs<
      "oneSectionTopWidgetQueue" | "topStoriesBlockQueueItemsEdge"
    >;
  }[],
  hasNextPage: boolean,
) => {
  const chunks = [];
  const chunkSizes = [3, 4, 4, 3, 4, 4];
  let currentChunkSize = 0;
  for (const size of chunkSizes) {
    const chunk = topStories.slice(currentChunkSize, currentChunkSize + size);
    if (chunk.length === size || !hasNextPage) {
      chunks.push(chunk);
    }
    currentChunkSize += size;
  }

  return chunks;
};

export const parseList = (
  hasNextPage: boolean,
  topStories: readonly {
    readonly " $fragmentSpreads": FragmentRefs<
      "oneSectionTopWidgetQueue" | "topStoriesBlockQueueItemsEdge"
    >;
  }[],
) => {
  const maximinContent = 49;
  const heroContentsGroupSize = 3;
  const contentGroupSize = 25;

  const heroContentsGroup = topStories?.slice(0, heroContentsGroupSize);
  const contentGroup = getInitialTopStories(
    topStories.slice(heroContentsGroupSize, contentGroupSize),
    hasNextPage,
  );
  const restContentsComponents = chunkArray(
    topStories?.slice(contentGroupSize, maximinContent),
    hasNextPage,
  );

  return {
    contentGroup,
    heroContentsGroup,
    restContentsComponents,
  };
};
