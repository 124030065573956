/**
 * @generated SignedSource<<337088ffb7e13aca822fb23b17b3da61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subSectionPostMagazineQuery$data = {
  readonly articles: {
    readonly " $fragmentSpreads": FragmentRefs<"subSectionPostMagazineIndexArticleList">;
  } | null | undefined;
  readonly section: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly sponsorQueue: {
    readonly " $fragmentSpreads": FragmentRefs<"hooksPickSubSectionArticle">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"headerPostMagazineQuery" | "postMagazineNewsletter" | "subSectionPostMagazineProfileQuery">;
  readonly " $fragmentType": "subSectionPostMagazineQuery";
};
export type subSectionPostMagazineQuery$key = {
  readonly " $data"?: subSectionPostMagazineQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"subSectionPostMagazineQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "Variable",
  "name": "entityId",
  "variableName": "entityId"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "articlesQueueName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    },
    {
      "defaultValue": 23,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "postMagazineSubSectionTopicQueueName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sponsorQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "subSectionPostMagazineQuery",
  "selections": [
    {
      "alias": "articles",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "articlesQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "after",
              "variableName": "after"
            },
            (v0/*: any*/)
          ],
          "kind": "FragmentSpread",
          "name": "subSectionPostMagazineIndexArticleList"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "sponsorQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "sponsorQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "hooksPickSubSectionArticle",
          "selections": [
            {
              "alias": null,
              "args": (v1/*: any*/),
              "concreteType": "QueueItemConnection",
              "kind": "LinkedField",
              "name": "items",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QueueItemsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "postMagazinePrimaryContent"
                            }
                          ],
                          "type": "Content",
                          "abstractKey": "__isContent"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": (v1/*: any*/),
          "argumentDefinitions": [
            {
              "defaultValue": null,
              "kind": "LocalArgument",
              "name": "first"
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            (v2/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "version",
              "value": 2
            }
          ],
          "kind": "ScalarField",
          "name": "advertZone",
          "storageKey": "advertZone(version:2)"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "headerPostMagazineQuery"
    },
    {
      "args": [
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "postMagazineSubSectionTopicQueueName",
          "variableName": "postMagazineSubSectionTopicQueueName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "subSectionPostMagazineProfileQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "postMagazineNewsletter"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "80031f6f4d5161e33ed4ca3537ce6b77";

export default node;
