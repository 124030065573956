/**
 * @generated SignedSource<<4e61fa672c80f27d18331c16a6b7685b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type subSectionParentSectionQuery$variables = {
  entityUuid?: string | null | undefined;
};
export type subSectionParentSectionQuery$data = {
  readonly section: {
    readonly entityId: string;
  };
};
export type subSectionParentSectionQuery = {
  response: subSectionParentSectionQuery$data;
  variables: subSectionParentSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityUuid"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "entityUuid",
        "variableName": "entityUuid"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "subSectionParentSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "section",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "subSectionParentSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Section",
        "kind": "LinkedField",
        "name": "section",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d6384649ae7a11bd51c605342111627",
    "id": null,
    "metadata": {},
    "name": "subSectionParentSectionQuery",
    "operationKind": "query",
    "text": "query subSectionParentSectionQuery(\n  $entityUuid: String\n) {\n  section(filter: {entityUuid: $entityUuid}) {\n    entityId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "748f026668c37825445c00f32477024f";

export default node;
