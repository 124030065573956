import type { FunctionComponent, ReactNode } from "react";
import type { FragmentRefs } from "relay-runtime";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { ContentItemPostMagazinePrimary } from "scmp-app/components/content/content-item-render/variants/post-magazine-primary";
import { ContentItemPostMagazineSecondary } from "scmp-app/components/content/content-item-render/variants/post-magazine-secondary";

import {
  AdsContainer,
  ArticlesContainer,
  Container,
  FirstSet,
  SecondSetContainer,
  ThirdSetContainer,
} from "./styles";

export type Props = {
  adsConfigs: [AdSlotProps, AdSlotProps, AdSlotProps];
  isRestContent?: boolean;
  slots?: {
    LastItemAd?: ReactNode;
  };
  topStories: {
    readonly " $fragmentSpreads": FragmentRefs<
      "postMagazinePrimaryContent" | "postMagazineSecondaryContent"
    >;
  }[];
};

export const PostMagazineTopStories: FunctionComponent<Props> = ({
  adsConfigs,
  isRestContent = false,
  slots,
  topStories,
}) => {
  const thirdSetEndIndex = slots?.LastItemAd ? 5 : 6;
  const firstSet = topStories.slice(0, 1);
  const secondSet = topStories.slice(1, 3);
  const thirdSet = topStories.slice(3, thirdSetEndIndex);
  const fourthSet = topStories.slice(thirdSetEndIndex);

  if (isRestContent && topStories.length < 2) return null;

  return (
    <Container>
      <ArticlesContainer>
        <FirstSet>
          <ContentItemPostMagazinePrimary
            imageResponsiveVariants={{
              desktopUp: "size1200x800",
              mobileUp: "size768x768",
              tabletUp: "size1200x800",
            }}
            reference={firstSet[0]}
            topicLinkVariant={{ type: "main" }}
          />
        </FirstSet>
        <AdsContainer>
          {adsConfigs.map((config, configIndex) => (
            <AdSlot key={configIndex} {...config} />
          ))}
        </AdsContainer>
      </ArticlesContainer>
      {secondSet.length > 0 && (
        <SecondSetContainer>
          {secondSet.map((reference, index) => (
            <ContentItemPostMagazinePrimary
              imageResponsiveVariants={{
                desktopUp: "size1200x800",
                mobileUp: "size1200x800",
                tabletUp: "size1200x800",
              }}
              key={index}
              reference={reference}
              topicLinkVariant={{ type: "main" }}
            />
          ))}
        </SecondSetContainer>
      )}

      {thirdSet.length > 0 && (
        <ThirdSetContainer>
          {thirdSet.map((reference, index) => (
            <ContentItemPostMagazinePrimary
              imageResponsiveVariants={{
                desktopUp: "size1200x800",
                mobileUp: "size540x360",
                tabletUp: "size1200x800",
              }}
              key={index}
              reference={reference}
              topicLinkVariant={{ type: "main" }}
            />
          ))}
          {slots?.LastItemAd && slots.LastItemAd}
        </ThirdSetContainer>
      )}

      {fourthSet.length > 0 && (
        <ArticlesContainer>
          <ContentItemPostMagazineSecondary
            reference={fourthSet[0]}
            topicLinkVariant={{ type: "main" }}
          />
        </ArticlesContainer>
      )}
    </Container>
  );
};

PostMagazineTopStories.displayName = "PostMagazineTopStories";
