/**
 * @generated SignedSource<<30677a110fc4603bc9e1c6a2971efc98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksPickSubSectionArticle$data = {
  readonly items: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"postMagazinePrimaryContent">;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "hooksPickSubSectionArticle";
};
export type hooksPickSubSectionArticle$key = {
  readonly " $data"?: hooksPickSubSectionArticle$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksPickSubSectionArticle">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksPickSubSectionArticle"
};

(node as any).hash = "78a7e955f52e8f9e7b9112c927b52bc3";

export default node;
