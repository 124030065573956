import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import {
  Container as ContentItemPostMagazinePrimaryContainer,
  CoverImage,
  StyledEntityLink as PrimaryEntityLink,
  Topic as PrimaryTopic,
  StyledCoverEntityLink,
} from "scmp-app/components/content/content-item-render/variants/post-magazine-primary/styles";

export const Container = styled.div``;

const border = css`
  margin-block-end: 40px;
  padding-block-end: 40px;
  border-block-end: 1px solid #777777;
`;

export const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  ${border}
  ${ContentItemPostMagazinePrimaryContainer} {
    flex: 1;
  }

  ${theme.breakpoints.up("tablet")} {
    flex-direction: row;
  }

  ${PrimaryEntityLink} {
    ${theme.breakpoints.up("tablet")} {
      font-size: 24px;
    }
    ${theme.breakpoints.up("desktop")} {
      font-size: 32px;
    }
  }
`;

export const FirstSet = styled.div`
  flex: 1;

  ${theme.breakpoints.up("tablet")} {
    border-inline-end: 1px solid #777777;

    padding-inline-end: 40px;
  }

  ${theme.breakpoints.only("mobile")} {
    ${StyledCoverEntityLink}, ${CoverImage} {
      aspect-ratio: 1/1;
    }
  }

  ${PrimaryEntityLink} {
    ${theme.breakpoints.up("tablet")} {
      font-size: 28px;
    }
    ${theme.breakpoints.up("desktop")} {
      font-size: 32px;
    }
  }
`;

export const AdsContainer = styled.div``;

export const SecondSetContainer = styled(ArticlesContainer)`
  ${PrimaryEntityLink} {
    font-size: 28px;
    ${theme.breakpoints.up("desktop")} {
      font-size: 32px;
    }
  }
`;

export const ThirdSetContainer = styled(ArticlesContainer)`
  & > div {
    flex: 1;
  }

  ${theme.breakpoints.only("mobile")} {
    gap: 24px;
    ${ContentItemPostMagazinePrimaryContainer} {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    ${StyledCoverEntityLink} {
      inline-size: 158px;
      block-size: 105.333px;
      margin-block-end: 0;
    }

    ${PrimaryEntityLink} {
      font-size: 20px;
      letter-spacing: -0.6px;
    }

    ${PrimaryTopic} {
      > a {
        margin-block-start: 8px;

        font-size: 12px;
        letter-spacing: -0.012px;
      }
    }
  }

  ${PrimaryEntityLink} {
    ${theme.breakpoints.up("desktop")} {
      font-size: 28px;
    }
  }
`;
