import { notEmpty } from "@product/scmp-sdk";
import first from "lodash/first";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";
import { useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLink } from "shared/components/common/base-link";
import { section as sectionData } from "shared/data";

import { EntityLink } from "scmp-app/components/entity-link";
import { page as SectionPage } from "scmp-app/pages/section";
import { Variant } from "scmp-app/pages/section/enums";
import type { subSectionStyleHeadQuery$key } from "scmp-app/queries/__generated__/subSectionStyleHeadQuery.graphql";

import {
  Container,
  LogoContainer,
  SectionList,
  SectionListContainer,
  SectionNameText,
  StyledBaseImage,
  StyledEntityLink,
  StyledLogo,
  SubSubSectionName,
} from "./styles";

type Props = {
  className?: string;
  reference: subSectionStyleHeadQuery$key;
};
export const SubSectionStyleHead: FunctionComponent<Props> = ({ className, reference }) => {
  const { relatedSections, section } = useFragment(
    graphql`
      fragment subSectionStyleHeadQuery on Query
      @argumentDefinitions(
        entityId: { type: "String!" }
        relatedSectionsQueueName: { type: "String!" }
      ) {
        relatedSections: queue(filter: { name: $relatedSectionsQueueName }) {
          items(first: 10) {
            edges {
              node {
                ... on Section {
                  name
                  entityId
                }
                ...entityLink
              }
            }
          }
        }
        section(filter: { entityId: $entityId }) {
          name
          parentSection
          images(filter: { type: { values: ["logo"] } }) {
            url
          }
          fullSectionPath {
            name
            entityUuid
            images(filter: { type: { values: ["logo"] } }) {
              url
            }
            ...entityLink
          }
          ...entityLink
        }
      }
    `,
    reference,
  );

  const isSubSubSection = useMemo(
    () => section.parentSection !== sectionData.style.entityUuid,
    [section.parentSection],
  );

  const renderSectionLogo = () => {
    const targetSection = isSubSubSection
      ? section.fullSectionPath?.find(s => s?.entityUuid === section.parentSection)
      : section;
    if (!targetSection) return null;
    const imageUrl = first(targetSection.images)?.url;

    return (
      <EntityLink reference={targetSection}>
        {imageUrl ? (
          <StyledBaseImage
            $aspectRatio="auto"
            $background="none"
            $objectFit="cover"
            alt={targetSection.name}
            disableLinkingData
            lazyload
            src={imageUrl}
          />
        ) : (
          <SectionNameText>{"// " + targetSection.name}</SectionNameText>
        )}
      </EntityLink>
    );
  };

  const router = useRouter();
  const currentEntityId = router.query.entityId;

  const renderRelatedSections = useCallback(() => {
    const relatedSectionsItems = relatedSections?.items?.edges.filter(notEmpty) ?? [];
    if (relatedSectionsItems.length === 0) return null;
    return (
      <SectionListContainer>
        <SectionList>
          {relatedSectionsItems.map(({ node: section }, index) => (
            <StyledEntityLink key={index} reference={section}>
              <SubSubSectionName $isActive={currentEntityId === section.entityId}>
                {section.name}
              </SubSubSectionName>
            </StyledEntityLink>
          ))}
        </SectionList>
      </SectionListContainer>
    );
  }, [currentEntityId, relatedSections?.items?.edges]);

  const { pathname: styleSectionPagePathname, query: styleSectionPageQuery } = SectionPage.route({
    appBarVariant: "scmp/magazines-style",
    entityId: sectionData.style.entityId,
    sectionVariant: Variant.Style,
    slide: "main",
  });

  return (
    <Container className={className}>
      <LogoContainer>
        <BaseLink
          as={sectionData.style.urlAlias}
          pathname={styleSectionPagePathname}
          query={{ ...styleSectionPageQuery }}
        >
          <StyledLogo />
        </BaseLink>
        {renderSectionLogo()}
      </LogoContainer>
      {renderRelatedSections()}
    </Container>
  );
};

SubSectionStyleHead.displayName = "SubSectionStyleHead";
