import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontBarlowSemiCondensed, theme } from "@product/scmp-sdk";

import { BaseImage } from "scmp-app/components/common/base-image";
import { EntityLink } from "scmp-app/components/entity-link";
import { HeaderLogo } from "scmp-app/components/section/section-style/styles";

export const Container = styled.div`
  padding-block-start: 16px;

  ${theme.breakpoints.up("tablet")} {
    padding-block-start: 48px;
  }
`;

export const StyledLogo = styled(HeaderLogo)`
  inline-size: auto;
  block-size: 76.5px;
  margin-block-end: 24px;

  ${theme.breakpoints.up("tablet")} {
    block-size: 132px;
    margin-block-end: 40px;
  }

  ${theme.breakpoints.up("desktop")} {
    block-size: 176px;
    margin-block-end: 48px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-block-end: 48px;
  ${theme.breakpoints.down("tablet")} {
    margin-block-end: 40px;
  }
`;

export const SectionNameText = styled.span`
  font-weight: normal;
  font-size: 22px;
  font-family: ${fontBarlowSemiCondensed};
  text-transform: uppercase;

  ${theme.breakpoints.up("desktop")} {
    font-size: 35px;
  }
`;

export const StyledBaseImage = styled(BaseImage)`
  inline-size: auto;
  block-size: 16px;

  ${theme.breakpoints.up("tablet")} {
    block-size: 26.5px;
  }

  ${theme.breakpoints.up("desktop")} {
    block-size: 35px;
  }
`;

export const Label = styled.div`
  color: #000000;
  font-weight: 700;
  text-transform: uppercase;

  ${theme.breakpoints.up("tablet")} {
    color: #333333;
  }
`;
export const Topic = styled.div`
  font-weight: 400;
`;
export const SectionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 18px;
  justify-content: center;
  align-items: center;

  max-inline-size: 1184px;

  ${theme.breakpoints.up("tablet")} {
    gap: 8px 24px;
  }
`;
export const SectionListContainer = styled.div`
  display: flex;
  justify-content: center;

  max-inline-size: 1184px;
  margin-inline: auto;
`;
export const StyledEntityLink = styled(EntityLink)`
  color: #000000;
  white-space: nowrap;

  ${theme.breakpoints.up("tablet")} {
    color: #333333;
  }
`;

const HoverStyle = css`
  text-decoration: underline;
  -webkit-text-stroke: 0.3px #222222;
`;

type SubSectionNameProps = {
  $isActive?: boolean;
};
export const SubSubSectionName = styled.div<SubSectionNameProps>`
  color: #222222;
  font-weight: 400;
  font-size: 15px;
  font-family: ${fontBarlowSemiCondensed};
  text-transform: uppercase;

  &:hover {
    ${HoverStyle}
  }

  ${theme.breakpoints.up("tablet")} {
    font-size: 20px;
  }

  ${props => {
    if (props.$isActive) {
      return HoverStyle;
    }
  }}
`;
