import { NoSsr } from "@mui/base";
import { theme } from "@product/scmp-sdk";
import { type FunctionComponent } from "react";
import { graphql, useFragment, usePaginationFragment } from "react-relay";

import { ClientSideSuspense } from "shared/components/common/client-side-suspense";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { AppFooter } from "scmp-app/components/app-footer";
import { ContentItemPostMagazinePrimary } from "scmp-app/components/content/content-item-render/variants/post-magazine-primary";
import { PostMagazineHeader } from "scmp-app/components/post-magazine/header";
import { PostMagazineNewsletter } from "scmp-app/components/post-magazine/post-magazine-newsletter";
import { PostMagazineTopStories } from "scmp-app/components/post-magazine/top-stories";
import { useSectionContext } from "scmp-app/components/section/contexts";
import { AdSlotContainer } from "scmp-app/components/section/section-post-magazine/styles";
import { useInfiniteScrollTriggerReference } from "scmp-app/lib/hooks";
import type { subSectionPostMagazineIndexArticleList$key } from "scmp-app/queries/__generated__/subSectionPostMagazineIndexArticleList.graphql";
import type { subSectionPostMagazineIndexArticleListPaginationQuery } from "scmp-app/queries/__generated__/subSectionPostMagazineIndexArticleListPaginationQuery.graphql";
import type { subSectionPostMagazineQuery$key } from "scmp-app/queries/__generated__/subSectionPostMagazineQuery.graphql";

import { getSubSectionAdsConfigs } from "./ads-config";
import { parseList } from "./helpers";
import { usePickSubSectionArticles } from "./hooks";
import { Container, Newsletter, NewsletterDivider, StyledLoading, Wrapper } from "./styles";
import { SubSectionPostMagazineProfile } from "./sub-section-post-magazine-profile";

export type Props = {
  className?: string;
  randomSeed?: string;
  reference: subSectionPostMagazineQuery$key;
};

export const SubSectionPostMagazine: FunctionComponent<Props> = ({
  className,
  randomSeed,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment subSectionPostMagazineQuery on Query
      @argumentDefinitions(
        articlesQueueName: { type: "String!" }
        after: { type: "String" }
        entityId: { type: "String!" }
        first: { type: "Int", defaultValue: 23 }
        postMagazineSubSectionTopicQueueName: { type: "String!" }
        sponsorQueueName: { type: "String!" }
      ) {
        articles: queue(filter: { name: $articlesQueueName }) {
          ...subSectionPostMagazineIndexArticleList @arguments(after: $after, first: $first)
        }
        sponsorQueue: queue(filter: { name: $sponsorQueueName }) {
          ...hooksPickSubSectionArticle @arguments(first: $first)
        }
        section(filter: { entityId: $entityId }) {
          entityId
          advertZone(version: 2)
        }
        ...headerPostMagazineQuery
        ...subSectionPostMagazineProfileQuery
          @arguments(
            entityId: $entityId
            postMagazineSubSectionTopicQueueName: $postMagazineSubSectionTopicQueueName
          )
        ...postMagazineNewsletter
      }
    `,
    reference_,
  );

  const {
    data: contentList,
    hasNext: hasNextPage,
    isLoadingNext: isLoading,
    loadNext,
  } = usePaginationFragment<
    subSectionPostMagazineIndexArticleListPaginationQuery,
    subSectionPostMagazineIndexArticleList$key
  >(
    graphql`
      fragment subSectionPostMagazineIndexArticleList on Queue
      @argumentDefinitions(after: { type: "String" }, first: { type: "Int", defaultValue: 7 })
      @refetchable(queryName: "subSectionPostMagazineIndexArticleListPaginationQuery") {
        items(first: $first, after: $after)
          @connection(key: "subSectionPostMagazineIndexArticleListQuery__items") {
          edges {
            node {
              ...postMagazinePrimaryContent
              ...postMagazineSecondaryContent
            }
          }
        }
      }
    `,
    data?.articles,
  );

  const { infiniteScrollTriggerReference } = useInfiniteScrollTriggerReference({
    hasNextPage,
    isLoading,
    onLoadMore: () => loadNext(7),
  });

  const { heroContentsGroup, restContentsComponents } = parseList(hasNextPage, contentList);
  const contentEdges = contentList?.items?.edges.map(({ node }) => node) ?? [];

  const { advertisement: baseAdvertisement } = useSectionContext();
  const sectionId = data.section.entityId;
  const adZone = data.section.advertZone ?? "default";

  const largeStyles = {
    $height: 250,
    $padding: 20,
  };
  const smallStyles = {
    $height: 100,
    $padding: 10,
  };

  const bindAdSlotContainer = {
    $responsiveVariants: {
      desktopUp: largeStyles,
      mobileUp: smallStyles,
      tabletUp: smallStyles,
    },
  };

  const adsConfigs = getSubSectionAdsConfigs(sectionId, adZone);
  const sponsorArticleSlot = usePickSubSectionArticles(
    randomSeed ?? "fallback seed",
    data.sponsorQueue,
  );
  if (contentEdges.length === 0) return null;

  return (
    <Container className={className}>
      <Wrapper>
        <AdSlotContainer {...bindAdSlotContainer}>
          <NoSsr>
            <AdSlot
              adUnit="d_banner1"
              breakpoint={theme.breakpoints.up("desktop")}
              sizes={[
                [970, 250],
                [728, 90],
                [970, 90],
              ]}
              targeting={{
                ...baseAdvertisement.targeting,
                scsid: [sectionId],
              }}
              withBackground
              zone={baseAdvertisement.zone}
            />
            <AdSlot
              adUnit="m_banner3"
              breakpoint={theme.breakpoints.down("desktop")}
              sizes={[
                [300, 100],
                [320, 100],
                [300, 50],
                [320, 50],
              ]}
              targeting={{
                ...baseAdvertisement.targeting,
                scsid: [sectionId],
              }}
              withBackground
              zone={baseAdvertisement.zone}
            />
          </NoSsr>
        </AdSlotContainer>
        <PostMagazineHeader reference={data} />
        <SubSectionPostMagazineProfile reference={data} />
        {heroContentsGroup && (
          <PostMagazineTopStories
            adsConfigs={adsConfigs[0]}
            slots={{
              LastItemAd: sponsorArticleSlot && (
                <ContentItemPostMagazinePrimary
                  imageResponsiveVariants={{
                    desktopUp: "size1200x800",
                    mobileUp: "size540x360",
                    tabletUp: "size1200x800",
                  }}
                  reference={sponsorArticleSlot.node}
                  topicLinkVariant={{ type: "main" }}
                />
              ),
            }}
            topStories={heroContentsGroup}
          />
        )}
        {restContentsComponents.map((restContents, index) => {
          if (!restContents) return null;
          return (
            <PostMagazineTopStories
              adsConfigs={adsConfigs[1]}
              isRestContent={true}
              key={index}
              topStories={restContents}
            />
          );
        })}
        {hasNextPage && <StyledLoading ref={element => infiniteScrollTriggerReference(element)} />}
      </Wrapper>
      {!hasNextPage && (
        <>
          <NewsletterDivider />
          <Newsletter>
            <PostMagazineNewsletter reference={data} />
          </Newsletter>
          <ClientSideSuspense>
            <AppFooter variant="post-magazine" />
          </ClientSideSuspense>
        </>
      )}
    </Container>
  );
};

SubSectionPostMagazine.displayName = "SubSectionPostMagazine";
