import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontBarlowSemiCondensed, theme } from "@product/scmp-sdk";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { AdSlotContainer } from "scmp-app/components/advertisement/ad-slots/ad-slot/styles";
import { StyledImage } from "scmp-app/components/common/base-image/styles";
import { ContentItemMagazinesStyleCardSquare } from "scmp-app/components/content/content-item-render/variants/magazines-style-card";
import {
  Headline as CardHeadline,
  Topic as CardTopic,
} from "scmp-app/components/content/content-item-render/variants/magazines-style-card/styles";

export const StyledContentItemMagazinesStyleCardSquare = styled(
  ContentItemMagazinesStyleCardSquare,
)`
  inline-size: 100%;
  padding-inline: 20px;

  ${StyledImage} {
    block-size: auto;
  }

  ${theme.breakpoints.up("tablet")} {
    padding-inline: 0;
  }
`;

export const ArticleContainer = styled.div`
  --column-gap-size: 0;
  --row-gap-size: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--row-gap-size) var(--column-gap-size);
  justify-content: center;

  inline-size: 100%;
  max-inline-size: 1184px;
  margin-block: 24px 40px;

  ${StyledContentItemMagazinesStyleCardSquare} {
    flex: 1 0 100%;
    row-gap: 4px;

    text-align: center;
  }

  ${theme.breakpoints.only("mobile")} {
    ${StyledContentItemMagazinesStyleCardSquare} {
      ${CardHeadline} {
        h2 {
          font-size: 20px;
          line-height: 26px;
        }
      }
      ${css`
        &:nth-child(5n + 1 of ${StyledContentItemMagazinesStyleCardSquare}) {
          row-gap: 8px;

          padding-inline: 0;

          ${CardHeadline} {
            h2 {
              font-size: 24px;
              line-height: 30px;
            }
          }
        }
      `}
    }
  }

  ${theme.breakpoints.up("tablet")} {
    --column-gap-size: 24px;
    --row-gap-size: 48px;
    justify-content: flex-start;

    ${StyledContentItemMagazinesStyleCardSquare} {
      flex: 0 0 calc(50% - var(--column-gap-size) / 2);
      row-gap: 14px;

      text-align: start;

      ${CardHeadline} {
        h2 {
          font-size: 24px;
          line-height: 30px;
        }
      }

      ${css`
        &:nth-child(5n + 1 of ${StyledContentItemMagazinesStyleCardSquare}) {
          flex: 1 0 calc(50% - var(--column-gap-size));
          row-gap: 16px;

          text-align: center;
          ${CardHeadline} {
            h2 {
              font-size: 32px;
              line-height: 36px;
            }
          }
          ${CardTopic} {
            font-size: 16px;
          }
        }
      `}
    }
  }
  ${theme.breakpoints.up("desktop")} {
    --column-gap-size: 32px;
    --row-gap-size: 80px;
    margin-block-start: 40px;

    ${StyledContentItemMagazinesStyleCardSquare} {
      ${CardHeadline} {
        h2 {
          font-size: 32px;
          line-height: 38px;
        }
      }

      ${css`
        &:nth-child(5n + 1 of ${StyledContentItemMagazinesStyleCardSquare}) {
          ${CardHeadline} {
            h2 {
              font-size: 40px;
              line-height: 48px;
            }
          }
        }
      `}
    }
  }
  ${theme.breakpoints.up("mediumDesktop")} {
    --column-gap-size: 40px;
  }
`;

export const StyledLoading = styled.div`
  inline-size: 100%;
  margin-block: 32px;

  color: #555555;
  font-weight: 400;
  font-size: 18px;
  font-family: ${fontBarlowSemiCondensed};
  line-height: 20px;
  text-align: center;
`;

const StyledAdSlot = styled(AdSlot)`
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;

  inline-size: 300px;
  block-size: initial;
`;

export const BannerAdSlot = styled(StyledAdSlot)`
  inline-size: 100%;
  padding-block: 24px;
`;

export const InlineAdSlotContainer = styled.div`
  ${theme.breakpoints.up("tablet")} {
    flex: 1 1;
    align-items: start;
  }
`;

export const InlineAdSlot = styled(StyledAdSlot)`
  ${AdSlotContainer} {
    min-block-size: min-content;
  }
`;

export const MobileAdSlot = styled(StyledAdSlot)`
  inline-size: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 80px;
  }

  ${theme.breakpoints.only("tablet")} {
    margin-inline: -12px;
  }
  ${theme.breakpoints.between("desktop", "mediumDesktop")} {
    padding-inline: 4px;
  }
  ${theme.breakpoints.up("mediumDesktop")} {
    padding-inline: 12px;
  }
`;
