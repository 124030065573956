import { theme } from "@product/scmp-sdk";
import { type FunctionComponent, useMemo } from "react";
import { graphql, useFragment, useLazyLoadQuery, usePaginationFragment } from "react-relay";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";
import { ClientSideSuspense } from "shared/components/common/client-side-suspense";
import { section as sectionData } from "shared/data/section";
import { useCurrentPageType } from "shared/hooks";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { useTopBannerAdSlot } from "scmp-app/components/advertisement/ad-slots/top-banner-ad-slot/hooks";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import { AppFooter } from "scmp-app/components/app-footer";
import { EntityOnelineMenu } from "scmp-app/components/entity-oneline-menu";
import { SectionTermLogo } from "scmp-app/components/home/term-logo/section";
import { useSectionContext } from "scmp-app/components/section/contexts";
import { SectionDescription } from "scmp-app/components/section/section-description";
import { SectionNewsletterWidget } from "scmp-app/components/section/section-newsletter-widget";
import { SectionTopWidgetOne } from "scmp-app/components/section/section-top/variant/one";
import { SubscriptionWidget } from "scmp-app/components/subscription-widget";
import { useInfiniteScrollTriggerReference } from "scmp-app/lib/hooks";
import type { subSectionIndexArticleList$key } from "scmp-app/queries/__generated__/subSectionIndexArticleList.graphql";
import type { subSectionIndexArticleListPaginationQuery } from "scmp-app/queries/__generated__/subSectionIndexArticleListPaginationQuery.graphql";
import type { subSectionParentSectionQuery } from "scmp-app/queries/__generated__/subSectionParentSectionQuery.graphql";
import type { subSectionQuery$key } from "scmp-app/queries/__generated__/subSectionQuery.graphql";

import { parseList } from "./helpers";
import { useSubSectionAndAdsWidgets } from "./hooks";
import IconScmpLabs from "./icon-scmp-labs.svg";
import {
  Container,
  ContentContainer,
  ContentWrapper,
  Header,
  HeroArticles,
  Newsletter,
  RelatedTopicContainer,
  SectionDescriptionContainer,
  StyledEntityLink,
  StyledLoading,
  StyledTopStoriesBlock,
  SubscriptionMessage,
  SubSectionName,
  TopStories1,
  TopStories2,
  TopStories3,
  TopStories4,
  TopStories5,
  TopStories6,
  TopStoriesRest,
  TopStoriesRestArticle,
} from "./styles";

type TopStoresTypes = typeof TopStories1;

const topStoriesComponentMap: Record<string, TopStoresTypes> = {
  TopStories1,
  TopStories2,
  TopStories3,
  TopStories4,
  TopStories5,
  TopStories6,
};

export type Props = {
  reference: subSectionQuery$key;
};
export const SubSection: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment subSectionQuery on Query
      @argumentDefinitions(
        articlesQueueName: { type: "String!" }
        after: { type: "String" }
        commentQueueName: { type: "String!" }
        entityId: { type: "String!" }
        first: { type: "Int", defaultValue: 23 }
        focusQueueName: { type: "String!" }
        hasParentSection: { type: "Boolean!" }
        multimediaQueueName: { type: "String!" }
        parentSectionUuid: { type: "String" }
        relatedTopicsQueueName: { type: "String" }
        scmpPlusPaywallTypeIds: { type: "[String]", defaultValue: [] }
        includeComment: { type: "Boolean!" }
        includeChinaFutureTechDiscovery: { type: "Boolean!" }
        includeFocusArticle: { type: "Boolean!" }
        includeMultimedia: { type: "Boolean!" }
        includePostMagazine: { type: "Boolean!" }
      ) {
        subSection: section(filter: { entityId: $entityId }) {
          name
          entityId
          parentSection
          ...sectionTermLogoSection
          ...sectionDescriptionSection
          advertZone(version: 2)
        }
        parentSection: section(filter: { entityUuid: $parentSectionUuid })
          @include(if: $hasParentSection) {
          name
          entityId
          ...entityLink
        }
        relatedTopics: queue(filter: { name: $relatedTopicsQueueName }) {
          items {
            edges {
              ...entityOnelineMenuQueueItemsEdge
            }
          }
        }
        articles: queue(filter: { name: $articlesQueueName }) {
          ...subSectionIndexArticleList @arguments(after: $after, first: $first)
        }
        ...hooksSubSectionWidgetAndAdsQuery
          @arguments(
            commentQueueName: $commentQueueName
            entityId: $entityId
            multimediaQueueName: $multimediaQueueName
            parentSectionUuid: $parentSectionUuid
            scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds
            focusQueueName: $focusQueueName
            includeComment: $includeComment
            includeChinaFutureTechDiscovery: $includeChinaFutureTechDiscovery
            includeFocusArticle: $includeFocusArticle
            includeMultimedia: $includeMultimedia
            includePostMagazine: $includePostMagazine
          )
        ...sectionNewsletterWidgetQuery @arguments(entityId: $entityId)
      }
    `,
    reference_,
  );

  const { advertisement: baseAdvertisement } = useSectionContext();
  const currentPageType = useCurrentPageType();
  useTopBannerAdSlot({
    desktop: {
      adUnit: "d_banner1",
      sizes: [
        [970, 250],
        [728, 90],
        [970, 90],
      ],
      targeting: {
        ...baseAdvertisement.targeting,
        scsid: [data.subSection.entityId],
      },
      zone: baseAdvertisement.zone,
    },
    mobile: {
      adUnit: "m_banner3",
      sizes: [
        [300, 100],
        [320, 100],
        [300, 50],
        [320, 50],
      ],
      targeting: {
        ...baseAdvertisement.targeting,
        scsid: [data.subSection.entityId],
      },
      zone: baseAdvertisement.zone,
    },
  });
  const {
    data: articlesWithPaging,
    hasNext: hasNextPage,
    isLoadingNext: isLoading,
    loadNext,
  } = usePaginationFragment<
    subSectionIndexArticleListPaginationQuery,
    subSectionIndexArticleList$key
  >(
    graphql`
      fragment subSectionIndexArticleList on Queue
      @argumentDefinitions(after: { type: "String" }, first: { type: "Int", defaultValue: 23 })
      @refetchable(queryName: "subSectionIndexArticleListPaginationQuery") {
        items(first: $first, after: $after)
          @connection(key: "subSectionIndexArticleListQuery__items") {
          edges {
            ...oneSectionTopWidgetQueue
            ...topStoriesBlockQueueItemsEdge
          }
        }
      }
    `,
    data?.articles,
  );

  const parentSectionData = useLazyLoadQuery<subSectionParentSectionQuery>(
    graphql`
      query subSectionParentSectionQuery($entityUuid: String) {
        section(filter: { entityUuid: $entityUuid }) {
          entityId
        }
      }
    `,
    {
      entityUuid: data.subSection.parentSection,
    },
    { fetchPolicy: "store-or-network" },
  );

  const parentSectionEntityId = parentSectionData?.section?.entityId ?? "";

  const pageType = useCurrentPageType();

  const { infiniteScrollTriggerReference } = useInfiniteScrollTriggerReference({
    hasNextPage,
    isLoading,
    onLoadMore: () => loadNext(8),
  });

  const topStories = articlesWithPaging?.items?.edges ?? [];
  const isShowAdditionalWidget = topStories.length > 31;
  const { render: subSectionWidgets } = useSubSectionAndAdsWidgets(data, isShowAdditionalWidget);
  const isShowLabsLogo = useMemo(
    () => data.subSection.entityId === sectionData.recap.entityId,
    [data.subSection.entityId],
  );

  if (topStories.length === 0) return null;

  const { contentGroup, heroContentsGroup, restContentsComponents } = parseList(
    hasNextPage,
    topStories,
  );

  const topStoriesComponents = contentGroup.map((group, index) => ({
    content: group,
    type: `TopStories${index + 1}`,
  }));

  /** [[25th, 26th, 27th, 28th], ...] */
  const restStoriesComponents = restContentsComponents;
  const relatedTopics = data?.relatedTopics?.items?.edges ?? [];

  const sectionLogo = data.subSection ? <SectionTermLogo reference={data.subSection} /> : null;

  return (
    <Container>
      <ContentContainer>
        <ContentWrapper
          $isShowAds={topStoriesComponents.length > 6}
          $parentSectionEntityId={parentSectionEntityId}
          $subSectionEntityId={data.subSection.entityId}
        >
          <SectionDescriptionContainer>
            <SectionDescription reference={data.subSection} sectionId={data.subSection.entityId} />
          </SectionDescriptionContainer>
          <Header>
            {sectionLogo}
            {data?.parentSection && (
              <StyledEntityLink
                query={{
                  module: "sub_section_menu",
                  pgtype: currentPageType,
                }}
                reference={data.parentSection}
              >
                {data?.parentSection?.name} /
              </StyledEntityLink>
            )}
            <SubSectionName>
              {data?.subSection?.name} {isShowLabsLogo && <IconScmpLabs />}
            </SubSectionName>

            <BaseLinkContextProvider
              customQueryParameters={{
                module: "related_topics",
                pgtype: currentPageType,
              }}
            >
              {relatedTopics.length > 0 && (
                <RelatedTopicContainer $withSectionLogo={sectionLogo !== null}>
                  <EntityOnelineMenu
                    extraSlide={<div>Related Topics:</div>}
                    reference={relatedTopics}
                  />
                </RelatedTopicContainer>
              )}
            </BaseLinkContextProvider>
          </Header>

          <BaseLinkContextProvider
            customQueryParameters={{
              module: "top_story",
              pgtype: pageType,
            }}
          >
            <HeroArticles>
              {heroContentsGroup && (
                <SectionTopWidgetOne
                  preferTopicLabel
                  primaryWithSection
                  primaryWithTopic
                  reference={heroContentsGroup}
                  secondaryWithTopic
                />
              )}
            </HeroArticles>

            {topStoriesComponents.map((topStories, index) => {
              const ComponentType = topStoriesComponentMap[topStories.type];
              if (!topStories.content) {
                return null;
              }
              const isShowAds = index === 0 || index === 3;

              return (
                <ComponentType key={topStories.type + index}>
                  <StyledTopStoriesBlock
                    isShowAds={isShowAds}
                    reference={topStories.content}
                    slots={{
                      LastItemAd: (
                        <>
                          <AdSlot
                            adUnit={index === 0 ? "d_native2a" : "d_native2c"}
                            breakpoint={theme.breakpoints.up("desktop")}
                            sizes={[[1, 1], "fluid"]}
                            targeting={{
                              paid: TargetingPaidType.NotArticle,
                              scsid: [data.subSection.entityId],
                            }}
                            zone={data?.subSection?.advertZone ?? "default"}
                          />
                          <AdSlot
                            adUnit={index === 0 ? "m_native2a" : "m_native2c"}
                            breakpoint={theme.breakpoints.only("tablet")}
                            sizes={[[1, 1], "fluid"]}
                            targeting={{
                              paid: TargetingPaidType.NotArticle,
                              scsid: [data.subSection.entityId],
                            }}
                            zone={data?.subSection?.advertZone ?? "default"}
                          />
                          <AdSlot
                            adUnit="m_native2a"
                            breakpoint={theme.breakpoints.only("mobile")}
                            sizes={[[1, 1], "fluid"]}
                            targeting={{
                              paid: TargetingPaidType.NotArticle,
                              scsid: [data.subSection.entityId],
                            }}
                            zone={data?.subSection?.advertZone ?? "default"}
                          />
                        </>
                      ),
                    }}
                    withTopic={true}
                  />
                </ComponentType>
              );
            })}
            <TopStoriesRest>
              {restStoriesComponents.map((restStores, index) => {
                if (!restStores) return null;
                return (
                  <TopStoriesRestArticle key={"rest" + index}>
                    <StyledTopStoriesBlock reference={restStores} withTopic={true} />
                  </TopStoriesRestArticle>
                );
              })}
              {hasNextPage && (
                <StyledLoading ref={element => infiniteScrollTriggerReference(element)} />
              )}
            </TopStoriesRest>
          </BaseLinkContextProvider>
          <ClientSideSuspense>{subSectionWidgets()}</ClientSideSuspense>
          <BaseLinkContextProvider
            customQueryParameters={{
              module: "sub_msg_1",
              pgtype: pageType,
            }}
          >
            <SubscriptionMessage>
              <SubscriptionWidget />
            </SubscriptionMessage>
          </BaseLinkContextProvider>
          {!hasNextPage && (
            <Newsletter>
              <SectionNewsletterWidget reference={data} />
            </Newsletter>
          )}
        </ContentWrapper>
      </ContentContainer>
      {!hasNextPage && <AppFooter />}
    </Container>
  );
};

SubSection.displayName = "SubSection";
